import React, { useEffect, useRef, useState } from 'react';
import './registration.css';
import countries from './countries';
import country_codes from './country-codes';
import us_states from './us-states';
// import {createUser} from '../signup.js';
import { usePasswordless } from './hooks';
import Spinner from './spinner';
import { getEnvironmentValueOf } from '../../src/main'
import Modal from './Modal';
import { useToast } from "./ToastContextProvider";
import OtpModal from '../../src/OtpModal'
import Confirmation from '../../src/Confirmation'
import { useLocation } from 'react-router';
function  Registration(){
  const {
    authenticateWithSRP,
    createUser
  } = usePasswordless();
  interface FormData {
    email: string;
    first_name: string;
    last_name: string;
    company: string;
    address: string;
    city: string;
    zip_code: string;
    phone: string;
    password: string;
    password_confirm: string;
    state: string;
    country: string;
    country_code: string;
    // enable_mfa?: string;
    [key: string]: string;
  }
  interface FormErrors {
    [key: string]: string | undefined;
    email?: string;
    first_name?: string;
    last_name?: string;
    company?: string;
    address?: string;
    city?: string;
    zip_code?: string;
    phone?: string;
    password?: string;
    password_confirm?: string;
    state?: string;
  }

   const [formData, setFormData] = useState<FormData>({
    email: '',
    first_name: '',
    last_name: '',
    company: '',
    address: '',
    city: '',
    zip_code: '',
    phone: '',
    password: '',
    password_confirm: '',
    state: 'AL',
    country: 'United States',
    country_code: '+1',
    // enable_mfa: '',
  });
  interface StepNext {
    First : boolean;
    Second :boolean;
    Last : boolean;
  }
  const [stepNext, setStepNext] = useState<StepNext>({
    First : true,
    Second: false,
    Last: false
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [showTick, setShowTick] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToast } = useToast();
  const { hideToast } = useToast()
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isOtpVerified, setIsOtpVerfied] = useState(false);
  const [btnDisabled,setBtnDisabled] = useState(false);
  const [isReadonly, setIsReadonly] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const url_email = queryParams.get('email');
  const invite_encoded_token =  queryParams.get('code')
  useEffect(() => {
    if (url_email) {
      setFormData((prevFormData) => ({ ...prevFormData, email: url_email }));
      inputRef.current?.focus();
    }
  }, []);
  const color = (url_email != undefined && url_email != '') ? 'rgb(171 171 171 / 34%)' : ''

  const addressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const initializeAutocomplete = async () => {
      if (window.google) {
        const autocompleteInstance = new window.google.maps.places.Autocomplete(
          addressRef.current!,
          {
            fields: ["address_components", "name", "place_id"],
            types: ["geocode"],
          }
        );

        autocompleteInstance.addListener("place_changed", () => fillInAddress(autocompleteInstance));
      }
    };

    initializeAutocomplete();
  }, []);

  const fillInAddress = async (autocomplete : any) => {
    if (!autocomplete) return;

    const place = autocomplete.getPlace();
    let address = "";
    let postcode = "";
    let city = "";
    let state = "";
    let country = "";
    let city_locality = "";
    let subpremise = "";
    let city_administrative_level_2 = "";

    place.address_components?.forEach((component:any) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address = `${component.long_name} ${address}`;
          break;

        case "route":
          address += `${component.short_name} ${subpremise}`;
          break;

        case "postal_code":
          postcode = `${component.long_name}${postcode}`;
          break;

        case "postal_code_suffix":
          postcode = `${postcode}-${component.long_name}`;
          break;

        case "locality":
          city_locality = component.long_name
          break;

        case "administrative_area_level_3":
          city = component.long_name
          break;

        case "administrative_area_level_2":
          city_administrative_level_2 = component.long_name
          break;

        case "administrative_area_level_1":
          state = component.long_name
          break;

        case "country":
          country = component.long_name
          break;

        case "subpremise":
          subpremise = component.long_name;
          break;
      }
    });

    setFormData(prevData=>({
        ...prevData,
        address: address? address : (city_locality? city_locality : city),
        zip_code : postcode,
        city : city? city : (city_locality? city_locality : city_administrative_level_2),
        state : state,
        country : country
    }));
  }

    const handleChange = (e:any) => {
      if(e.target.name == 'email'){
        if(url_email){
          inputRef.current!.value = url_email
        }
        setShowTick(false);
        setFormErrors({...formErrors, [e.target.name] : ''});
      }
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
    const handleFocus = () => {
      setIsReadonly(false);
    };
  const handleBlur = async (e:any) => {
    setIsReadonly(true);
    if (formData.email != '' ){
      if(/^\S+@\S+\.\S+$/.test(formData.email)){
        setSpinner(true);
        try {
          const res = await handleUserEmailValidate();
          if(res.statusCode === 200){
            hideToast();
            if(res.pendingActivation == true){
              setBtnDisabled(false)
              if(!res.mobileVerified){
                setIsModalOpen(true);
                setIsOtpVerfied(false);
              }else{
                setBtnDisabled(true)
                setIsModalOpen(true);
                setIsOtpVerfied(true);
              }
              setShowTick(true);
              setFormErrors({ ['email'] : ''});
            }else if(res.isUserExisting == true){
              setShowTick(false);
              setBtnDisabled(true)
              setFormErrors({ ['email'] : 'An MrSign.in account with that email already exists. Please enter a new email.'});
            }else{
              setBtnDisabled(false)
              setShowTick(true);
            }
          }
          setSpinner(false);
        } catch (error) {
          if (error instanceof Error) {
            setBtnDisabled(true)
            setSpinner(false);
            setShowTick(false);
            showToast('There was an error while checking whether the user is already existing. Please retry later or contact the administrator.', 'danger');
          }
        }
      }else{
        setFormErrors({...formErrors, [e.target.name] : 'Given email address is invalid!'});
      }
    }
  }

  const handleUserEmailValidate = async  () => {
    const apiBaseURL = getEnvironmentValueOf("VITE_STORE_TOKEN_LAMBDA_URL");
    const checkUserApiUrl = apiBaseURL+ 'user/check-user?email=' + formData.email+'';
    try {
      const response = await fetch(checkUserApiUrl, {
        method: 'GET',
      });
      if(!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if(data.errorMessage){
        return;
      }
      return data;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error:', error.message);
      }
    }
  }

  const handleRegistrationPending = async  () => {
    const apiBaseURL = getEnvironmentValueOf("VITE_STORE_TOKEN_LAMBDA_URL");
    var mrsigninUrl = getEnvironmentValueOf("VITE_MRSIGNIN_URL");
    if(url_email){
      const redirectUrl = queryParams.get('redirectUrl');
      const mrsclientid = queryParams.get('mrsClientId');
      const clientName = queryParams.get('clientName');
      if(redirectUrl && mrsclientid && clientName && invite_encoded_token){
        mrsigninUrl = mrsigninUrl+"/clientRedirectionHandler/?redirectUrl="+ redirectUrl +"&mrsClientId="+ mrsclientid +"&clientName="+ clientName +"&invite_encoded_token="+ invite_encoded_token
      }
    }
    const resendActivationLinkApiUrl = apiBaseURL + 'user/resend-activation-link'
    const requestBody = {
      "username" : formData.email,
      redirectUri : mrsigninUrl
    }
    try {
      const response = await fetch(resendActivationLinkApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
        body : JSON.stringify(requestBody),
      });
      if(!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error:', error.message);
      }
    }
  }

  const handleCloseOtp = () => {
    setStepNext({First : true,Second : false,Last : false})
    setIsOtpModalOpen(false)
  }

  const handleCheckboxChange = (event:any) => {
    let isChecked: string;
    if(event.target.checked){
      isChecked = 'on';
    }
    else{
      isChecked = 'off';
      delete formData.enable_mfa;
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      enable_mfa: isChecked
    }));
    
  };

  const handleResendOtp = async  (mfaMethodName : any) => {
    const apiBaseURL = getEnvironmentValueOf("VITE_STORE_TOKEN_LAMBDA_URL");
    const mrsigninUrl = getEnvironmentValueOf("VITE_MRSIGNIN_URL");
    const resendOtpApiUrl = apiBaseURL + 'user/resend-mobile-verification-otp'
    const requestBody = {
      "username" : formData.email,
      redirectUri : mrsigninUrl,
      mfaMethodName : mfaMethodName
    }
    try {
      const response = await fetch(resendOtpApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
        body : JSON.stringify(requestBody),
      });
      if(!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error:', error.message);
      }
    }
  }

  async function confirmOtpHandler(){
    setSpinner(true)
    try{
      const res = await handleResendOtp("MobileOtpVerification");
      if(res.statusCode === 200){
        setStepNext({First : false,Second : true,Last : false})
        setIsOtpModalOpen(true);
      }
      setSpinner(false);
    }catch(error){
      setSpinner(false);
      showToast('There was an error while resending the activation mail. Please retry later or contact the administrator.', 'danger')
    }
  }

  async function confirmHandler(){
    setSpinner(true)
    try{
      const res = await handleRegistrationPending();
      setSpinner(false)
      if(res.statusCode === 200){
        showToast('Resent activation mail successfully.', 'success');
        setStepNext({First : false,Second : false,Last : true})
      }
    }catch(error){
      setSpinner(false);
      showToast('There was an error while resending the activation mail. Please retry later or contact the administrator.', 'danger')
    }
  }
  function closeHandler(){
    setBtnDisabled(true)
    setIsModalOpen(false)
  }

  const [usStatesDropDown, setUsStatesDropDown] = useState(true);
  const [spinner,setSpinner ] = useState(false)

  const handleCountryChange = (event:any) => {
    const selectedCountryCode = event.target.value;
    // validateZipcode(selectedCountryCode);
    if(selectedCountryCode === 'United States'){
      setUsStatesDropDown(true);
      setFormData(prevFormData => ({
        ...prevFormData, 
        state: 'AL'
      }));
    }else{
      setUsStatesDropDown(false);
      setFormData(prevFormData => ({
        ...prevFormData, 
        state: ''
      }));
    }
  };

  const validateForm = () => {
    let errors: FormErrors = {};
    let fields = Object.keys(formData) as Array<keyof FormData>;
    fields = fields.filter(item => item !== "enable_mfa");
    // if(!(formErrors.zip_code === undefined)){
    //   fields = fields.filter(item => item !== "zip_code");
    //   errors.zip_code = formErrors.zip_code;
    // }
    (fields).forEach((field) => {
      if (!formData[field].trim()) {
        if(field === 'state'){
          if(!usStatesDropDown){
            errors[field] = `${String(field.charAt(0).toUpperCase() + field.slice(1)).replace('_', ' ')} can't be blank`;
          }
        }else{
          // errors[field] = `${String(field.charAt(0).toUpperCase() + field.slice(1)).replace('_', ' ')} can't be blank`;
          if (((field != "password") && (field != "password_confirm"))){
            errors[field as string] = `${String(field).charAt(0).toUpperCase() + String(field).slice(1).replace('_', ' ')} can't be blank`;
          }
        }
      }
    });
  
    // let pass_regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.,:;'!@#\$%^&*_+-=|(){}<>])[0-9a-zA-Z.,:;'!@#\$%^&*_+-=|(){}<>]{8,}$/;
    let pass_regex=/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.,:;'!@#\$%^&*_+\-=|(){}<>])[0-9a-zA-Z.,:;'!@#\$%^&*_+\-=|(){}<>]{8,}$/;
    let phone_regex = /^([0-9]{3,10})$/;
    let address_regex = /^((?!').)*$/;

    if (!errors.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if(!errors.phone && !phone_regex.test(formData.phone)){
      errors.phone = 'Phone number invalid';
    }
    if(!errors.password && formData.password != '' && formData.password_confirm != ''){
      if(!/.{8,}/.test(formData.password)){
        errors.password = 'Password is too short (minimum is 8 characters)';
      }else if(!/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.,:;'!@#\$%^&*_+-=|(){}<>])/.test(formData.password)){
        errors.password = 'Password should contain atleast one lowercase, uppercase, digit and special character.';
      }
      else if(!pass_regex.test(formData.password)){
        errors.password = 'Password should contain minimum 8 characters with atleast one lowercase, uppercase, digit and special character';
      }
    }
    if (!errors.password_confirm && formData.password !== formData.password_confirm) {
      errors.password_confirm = 'The passwords does not match';
    }
    if(!errors.address && !address_regex.test(formData.address)){
      errors.address = 'Address should not contain apostrophe(\')';
    }
    return errors;
  };

  // const validateZipcode = async (country: string) => {
  //   try {
  //     let zipcode = formData['zip_code'];
  //     const accountConsoleUrl = getEnvironmentValueOf("VITE_ACCOUNT_CONSOLE_URL")
  //     // const ac_url = import.meta.env.VITE_ACCOUNT_CONSOLE_URL;
  //     // console.log("ac_url",ac_url);
  //     let error = '';
  //     const response = await fetch(accountConsoleUrl + 'admin/users/validate_zipcode?zip_code=' +zipcode+'&country='+country+'', {
  //       method: 'GET',
  //     });
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const response_data = await response.json();
  //     if(zipcode && response_data.flag === 'The zipcode you gave is invalid for the selected country. Please correct it!'){
  //       error = 'The zipcode you gave is invalid for the selected country. Please correct it!';
  //     }
  //     if(error){
  //       setFormErrors({ ...formErrors, zip_code: error });
  //     }
  //     else{
  //       delete formErrors.zip_code;
  //       setFormErrors({ ...formErrors});
  //     }
  //   } catch (error){
  //     if (error instanceof Error) {
  //     console.error('Error:', error.message);
  //     }
  //   }
  // };

  const handleSubmit = async (event:any) => {
    try {
      event.preventDefault();
      const errors = validateForm();
      if (Object.keys(errors).length === 0) {
        setSpinner(true);
        setFormErrors({});
        if(formData.enable_mfa !== 'on'){
          delete formData.enable_mfa;
        }
        const res = await handleUserEmailValidate()
        if(res.statusCode == 200 ){
          if (res.isUserExisting == true) {
            if (inputRef.current) {
              const syntheticEvent = new Event('blur', { bubbles: true });
              await handleBlur(syntheticEvent);
            }
            hideToast();
            return;
          }
        } else {
          showToast('There was an error accessing the backend. Please retry later or contact Administartor.', 'danger');
          return;
        }
        let phoneNumber = formData.phone
        if (phoneNumber.length < 10) {
          const zerosToAdd = 10 - phoneNumber.length;
          phoneNumber = '0'.repeat(zerosToAdd) + phoneNumber;
        }
        const user_deatils = await createUser({
          username: formData.email,
          password: formData.password,
          userAttributes: {
            "address": formData.address,
            "custom:firstname": formData.first_name,
            "custom:lastname": formData.last_name,
            "custom:city": formData.city,
            "custom:state": formData.state,
            "custom:zipcode": formData.zip_code,
            "custom:country": formData.country,
            "email": formData.email,
            "phone_number": formData.country_code + phoneNumber,
            "custom:company": formData.company,
            "phone_number_verified": "true",
            "email_verified": "true",
          },
        });
        // if (user_deatils && user_deatils.success) {
        //   authenticateWithSRP({
        //     username: user_deatils.username,
        //     password: user_deatils.password,
        //     clientMetadata: {}
        //   }).signedIn.then(response => {

        //     window.location.href = "/";
        //   }).catch(error => {
        //     console.error("Authentication failed:", error);
        //     window.location.href = "/";
        //   });
        // } else {
        //   window.location.href = "/register";
        // }
        const registrationLinkResponse = await user_deatils.signInLinkRequested.then(response => {
          setStepNext({First : false,Second : true,Last : false})
          setIsOtpModalOpen(true);
          setSpinner(false);
        }).catch(error => {
          console.error("Failed to create User:", error);
          showToast('There was an error while creating the User. Please retry later or contact the Administrator.', 'danger');
          setSpinner(false)
        });
      } else {
        setFormErrors(errors);
      }
    } catch(error) {
      console.error("User Creation failed", error);
      showToast('There was an error while creating the User. Please retry later or contact the Administrator.', 'danger');
    }finally {
      setSpinner(false);
    }
  };

  return (
    <>
    <header className="header pt-4 pb-5">
      <div className="container">
        <div className="row">
            <div className="col">
                <a className="back text-dark anchor-tag" href="<!--= previous_step -->">
                    <i className="fas fa-long-arrow-left" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div className="row">
          <div className="col-8">
              <a className="navbar-brand anchor-tag" href="/" data-turbolinks="false">                      
                <img className="registration-logo" alt="MrSign.in" src="/all-assets/logos/mr-signin.svg"/>
                <span className="pl-2 version"></span>
              </a>
          </div>
          <div className="col-4 text-right">
              <h2 className="text-grey">
                  <span className="text-100 text-secondary">
                        Register
                  </span>
              </h2>
          </div>
        </div>
      </div>
    </header>

    <section>
    <Modal isOpen={isModalOpen} onClose={closeHandler} onConfirm={confirmHandler} isOtpVerified={isOtpVerified} onOtpConfirm={confirmOtpHandler}/>
  <div className="container">
    <div className="row">
      <div className="col-sm"></div>
      <div className="col-sm-9">
        <h1 className="text-300 text-black text-center mb-4 heading">CREATE ACCOUNT</h1>
        <div className="f1-steps">
          <div className="f1-progress">
            <div className="f1-progress-line" style={stepNext.Last ? {width: "80.32%"} :stepNext.Second ? {width: "46.99%"} : {width: "13.66%"}}></div>
          </div>
          <div className="f1-step active">
            <div className="f1-step-icon"><svg xmlns="http://www.w3.org/2000/svg" className="fa fa-user" height="19px" margin-bottom="1px" width="47.25px" fill="white" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"/></svg></div>
            <p>User Registration</p>
          </div>
          <div className={(stepNext.Second || stepNext.Last) ? "f1-step active" : 'f1-step'}>
            <div className="f1-step-icon"><svg xmlns="http://www.w3.org/2000/svg" height="19px" margin-bottom="1px" width={(stepNext.Second || stepNext.Last) ? "47.25px" : "40.25px"} fill="white" viewBox="0 0 384 512"><path d="M16 64C16 28.7 44.7 0 80 0L304 0c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64L80 512c-35.3 0-64-28.7-64-64L16 64zM224 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM304 64L80 64l0 320 224 0 0-320z"/></svg></div>
            <p>Mobile Verification</p>
          </div>
          <div className={stepNext.Last ? "f1-step active" : "f1-step"}>
            <div className="f1-step-icon"><svg xmlns="http://www.w3.org/2000/svg" height="19px" margin-bottom="1px" width={stepNext.Last ? "47.25px" : "40.25px"} fill="white" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg></div>
            <p>Email Confirmation</p>
          </div>
        </div>
        {stepNext.First && (<form action="" method="post" name="create_account" className="create-user-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="email-field" style={{position:"relative"}}>
              <label>Email</label>
              <input type="email" name="email" disabled={isOtpModalOpen} value={formData.email} className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange} onBlur={handleBlur} ref={inputRef} onFocus={handleFocus} readOnly={isReadonly || (url_email != undefined && url_email != '')} style={{background:color}}/>
              {showTick && <span className="tick-mark"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18px" height="18px"><path fill="#44a25b" d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z"/></svg></span>}
              {formErrors.email && <div className="small text-error" id="email-error">{formErrors.email}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
            <label>First Name</label>
              <input type="text" name="first_name" value={formData.first_name} id="first_name"  className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.first_name && <div className="small text-error" id="email-error">{formErrors.first_name}</div>}
            </div>
            <div className="col-sm">
            <label>Last Name</label>
              <input type="text" name="last_name" value={formData.last_name} id="last_name"  className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.last_name && <div className="small text-error" id="email-error">{formErrors.last_name}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <label>Company</label>
              <input type="text" name="company" id="company" value={formData.company} className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.company && <div className="small text-error" id="email-error">{formErrors.company}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <label>Address</label>
              {/* <input type="text" name="address" id="address" value={formData.address} className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/> */}
              <input type="text" ref={addressRef} name="address" id="address" value={formData.address} className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.address && <div className="small text-error" id="email-error">{formErrors.address}</div>}
            </div>
            <div className="col-sm">
              <label>City</label>
              <input type="text" name="city" id="city" value={formData.city} className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.city && <div className="small text-error" id="email-error">{formErrors.city}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <label>Country</label>
              {!usStatesDropDown ? (
              <select name="country" id="country"  value={formData.country} onChange={(event) => {handleChange(event); handleCountryChange(event); }} className="input-box blink form-control border-secondary rounded-0 mb-4">
                {countries.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              ) : (
                <>
                  <input type="text" name="country" id="country" value={formData.country}  className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
                  {formErrors.country && (
                    <div className="small text-error" id="email-error">
                      {formErrors.country}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-sm">
                <label>State</label>
                {!usStatesDropDown ? (
                // {usStatesDropDown ? (
                <select
                  name="state"
                  id="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="input-box blink form-control border-secondary rounded-0 mb-4"
                >
                  {us_states.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              ) : (
                <>
                  <input type="text" name="state" id="state" value={formData.state}  className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
                  {formErrors.state && (
                    <div className="small text-error" id="email-error">
                      {formErrors.state}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-sm">
              <label>Zip/Postal Code</label>
              <input type="text" name="zip_code" id="zip_code" value={formData.zip_code}  className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.zip_code && <div className="small text-error" id="email-error">{formErrors.zip_code}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <label>Country Code</label>
              <select name="country_code" value={formData.country_code} disabled={isOtpModalOpen} onChange={handleChange} id="country_code" className="input-box blink form-control border-secondary rounded-0 mb-4">
              {country_codes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm">
              <label>Mobile Number</label>
              <input type="text" name="phone" id="phone" value={formData.phone} disabled={isOtpModalOpen} className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.phone && <div className="small text-error" id="email-error">{formErrors.phone}</div>}
            </div>
          </div>

          <div className="row form-row" id="enable-mfa">
            <div className="col-sm">
              <div className="custom-control custom-checkbox">
                <input className="package-standard custom-control-input" type="checkbox" checked={formData.enable_mfa == 'on'}  onChange={handleCheckboxChange} id="enable_mfa" name="enable_mfa"/>
                <label className="p-b-10 custom-control-label mfa-text" > I agree to receive SMS messages and one-time passcodes from Market Rithm about my account.</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <label>Password</label>
              <input type="password" name="password" id="password" value={formData.password} className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.password && <div className="small text-error" id="email-error">{formErrors.password}</div>}
            </div>
            <div className="col-sm">
              <label>Password Again</label>
              <input type="password" name="password_confirm" id="password_confirm" value={formData.password_confirm} className="input-box blink form-control border-secondary rounded-0 mb-4" onChange={handleChange}/>
              {formErrors.password_confirm && <div className="small text-error" id="email-error">{formErrors.password_confirm}</div>}
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-sm"></div>
            <div className="col-sm"></div>
            <div className="col-sm mb-3">
            <button className="btn btn-block btn-outline btn-lg border border-primary rounded-0 create-act-btn" id="create_account" onClick={handleSubmit} disabled={btnDisabled}>VERIFY</button>
            </div>
          </div>
        </form>)}
        {stepNext.Second && <OtpModal onCloseOtpModal={handleCloseOtp} phoneNumber={formData.phone} countryCode={formData.country_code} email={formData.email} setStepNext={setStepNext}/>}
        {stepNext.Last && <Confirmation/>}
        <div className="row">
          <div className="col text-center text-sm pt-3 pb-3 terms">
            By logging in you agree to our
            <a className="anchor-tag" href="https://marketrithm.com/terms"> Terms &amp; Conditions </a>
            and
            <a className="anchor-tag" href="https://marketrithm.com/privacy-compliance"> Privacy Policy</a>
          </div>
        </div>
      </div>
      <div className="col-sm"></div>
    </div>
  </div>
  {spinner &&(<Spinner/>)}
</section></>
  );
};

export default Registration;
